import { isAndroid, isIOS, isMobileOnly } from "react-device-detect";

export enum ScreenSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  '2xl' = '2xl',
}
export const screenSize = (gap?: number, grid?: number): {
  size: ScreenSize;
  width: number;
  grid: number;
  itemWidth: number;
} => {
  const isMobile = (isAndroid || isIOS) && isMobileOnly
  const calcuatedWidth = isMobile
    ? window.innerWidth - 32 - (gap ?? 0)
    : window.innerWidth - (gap ?? 0)

  if(calcuatedWidth > 668 && calcuatedWidth < 860) {
    return {
      size: ScreenSize.sm,
      width: 668,
      grid: grid ?? 3,
      itemWidth: Math.floor(calcuatedWidth / (grid ?? 3)),
    }
  }
  else if(calcuatedWidth > 860 && calcuatedWidth < 1052) {
    return {
      size: ScreenSize.md,
      width: 860,
      grid: grid ?? 4,
      itemWidth: Math.floor(calcuatedWidth / (grid ?? 4)),
    }
  }
  else if(calcuatedWidth > 1052 && calcuatedWidth < 1244) {
    return {
      size: ScreenSize.lg,
      width: 1052,
      grid: grid ?? 5,
      itemWidth: Math.floor(calcuatedWidth / (grid ?? 5)),
    }
  }
  else if(calcuatedWidth > 1244 && calcuatedWidth < 1436) {
    return {
      size: ScreenSize.xl,
      width: 1244,
      grid: grid ?? 6,
      itemWidth: Math.floor(calcuatedWidth / (grid ?? 6)),
    }
  }
  else if (calcuatedWidth > 1436) {
    return {
      size: ScreenSize['2xl'],
      width: 1436,
      grid: grid ?? 7,
      itemWidth: Math.floor(calcuatedWidth / (grid ?? 7)),
    }
  } else {
    return {
      size: ScreenSize.xs,
      width: 288,
      grid: grid ?? 2,
      itemWidth: Math.floor(calcuatedWidth / (grid ?? 2)),
    }
  }
}