import type { OpenLibraryBookByReviewsAndUserIdOutput } from '#app/utils/book/book.server.interface';
import type { Keyword } from '@prisma/client';
import type { SerializeFrom } from '@remix-run/node';

import React from 'react';
import { match } from 'ts-pattern';

import type { BookKeywordsTag, BookUserTag } from '../ui/book/book';

import Book from '../ui/book/book';

interface LibraryItemProps {
  hoverOff?: boolean;
  isLoading: boolean,
  keyword?: SerializeFrom<Keyword> | null,
  onClick: (id: number) => void,
  review: SerializeFrom<OpenLibraryBookByReviewsAndUserIdOutput[0]>,
  tag: 'keywords' | 'user';
  widthPx: number; 
}
export const LibraryItem: React.FC<LibraryItemProps> = ({
  hoverOff,
  isLoading,
  keyword,
  onClick,
  review,
  tag, 
  widthPx,
}) => {
  const width = `${(widthPx)/16}rem`;
  return (
    <Book
      actions={
          match(review)
            .with({isUploaded: true}, () => {
              return 'read';
            })
            .with({isLiked: true}, () => {
              return 'saved';
            })
            .with({isLiked: false, isUploaded: false}, () => {
              return 'wantRead';
            })
            .otherwise(() => {
              return undefined;
            }) as 'read' | 'saved' | 'wantRead'
      }
      book={review.source}
      hoverOff={hoverOff ?? false}
      loading={isLoading ? 'true' : 'false'}
      onClick={() => onClick(review.bookId)}
      review={review}
      style={{ width }}
      tag={
        match(tag)
          .returnType<BookKeywordsTag | BookUserTag>()
          .with('keywords', () => {
            if(!keyword) {
              const newArr = review.keywords;
              const uniqueIds = newArr.map(keyword => keyword.id);
              const uniqueKeywords = newArr.filter((keyword, index) => uniqueIds.indexOf(keyword.id) === index);
              return {
                disabledKeyword: undefined,
                keywords       : uniqueKeywords.map((keyword) => keyword.name),
              };
            } else {
              const newArr = [keyword, ...review.keywords];
              const uniqueIds = newArr.map(keyword => keyword.id);
              const uniqueKeywords = newArr.filter((keyword, index) => uniqueIds.indexOf(keyword.id) === index);
              return {
                disabledKeyword: keyword.name,
                keywords       : uniqueKeywords.map((keyword) => keyword.name),
              };
            }
          })
          .with('user', () => {
            return {
              id      : review.owner.id,
              imageUrl: review.owner.imageUrl || '',
              job     : review.owner.displayCareer || review.owner.careerKeyword!,
              name    : review.owner.name || review.owner.email.split('@')[0],
            };
          })
          .exhaustive()
      }
    />
  );
};